<template>
  <div class="search">
    <pageHead title="搜索" class="topicDetailView_head" />
    <div class="search_input">
      <searchBox
        ref="searchBox"
        @input="handleSearch"
        @clear="handleClear"
        v-model="listQueryParams.keyword"
        :placeholder="placeholder"
        slot="center"
        class="searchBox"
      />
      <div slot="right" class="head-right">
        <span class="text" @click="handleCancel">取消</span>
      </div>
    </div>
    <div
      class="listWrap"
      ref="listWrap"
      v-infinite-scroll="loadMore"
      infinite-scroll-distance="50"
    >
      <div class="tabs" v-if="showTabs" v-show="tabAccording">
        <div
          class="tabs_item"
          v-for="(item, index) in tabsList"
          :key="index"
          @click="changeTabs(item, index)"
        >
          <span
            class="tabs_text"
            :class="{ tabsActive: index === tabsActiveIndex }"
          >{{ item.title }}</span
          >
        </div>
      </div>
      <!-- todo -->
      <!--      <div class="timeWarp" v-if="this.records.length > 0"><span>用时 {{ times }} 秒，</span>
        <span>为您查询到 {{ total }} 条结果</span></div>-->

      <!-- 帖子搜索结果展示 -->
      <search-article-content
        :isLoading="isLoading"
        :content="records"
        :whetherSearch="whetherSearchPost"
        @follow="follow"
        v-if="tabsActiveIndex === 0"
      />
      <!-- 圈子搜索结果展示 -->
      <search-cricle-content
        :isLoading="isLoading"
        :content="records"
        :searchKey="listQueryParams.keyword"
        :whetherSearch="whetherSearchCircle"
        v-if="tabsActiveIndex === 1"
      />
      <!-- 搜索成员 -->
      <search-person-content
        :isLoading="isLoading"
        :content="records"
        :whetherSearch="whetherSearchPersonnel"
        v-if="tabsActiveIndex === 2"
      />
      <no-more
        v-if="!listQueryParams.hasNextPage && this.records.length > 0"
        style="margin-top: 14px"
      />
    </div>
  </div>
</template>

<script>
import {setItem, getItem, removeItem} from '@/utils/storage'
import pageHead from '@/components/pageHead'
import searchBox from '@/components/searchBox'
import searchCricleContent from './components/searchCricleContent'
import searchPersonContent from './components/searchPersonContent'
import {getSearch} from '@/api/article'
import NoMore from '@/components/uitls/NoMore'
import {attAdd, followRemove} from '@/api/att'
import {handleHeightLight, handleContentHeightLight} from '@/utils/utils'
import {searchCircleByTitle} from '@/api/circle'
import SearchArticleContent from '@/views/app/common/search/components/SearchArticleContent'
import {getZeekrUser} from '@/api/suser'

let timer

export default {
  name: 'searchCommon',
  components: {
    SearchArticleContent,
    pageHead,
    searchBox,
    searchCricleContent,
    searchPersonContent,
    NoMore,
  },
  data () {
    return {
      showTabs: this.$route.query.activeIndex === undefined,
      pageTitle: "搜索",
      tabsList: [
        {
          title: "帖子",
          id: 1,
        },
        {
          title: "圈子",
          id: 2,
        },
        {
          title: "员工",
          id: 3,
        },
      ],
      tabsActiveIndex: 0, // tabs选中项下标
      placeholder: "",
      isSearched: false,
      total: 0,
      times: 0,
      isLoading: false,
      listQueryParams: {
        pageNum: 1,
        pageSize: 10,
        hasNextPage: true,
        keyword: "",
      },
      records: [],
      whetherSearchPost: true,
      whetherSearchCircle: true,
      whetherSearchPersonnel: true,
      tabAccording: false,
      searchData: '', // 搜索数据，用于进入详情再返回搜索时保持
    };
  },
  created () {
    this.placeholder = this.$route.query.placeholder;
    const activeIndex = this.$route.query.activeIndex
    this.tabsActiveIndex = activeIndex === undefined ? 0 : parseInt(activeIndex + '');
  },
  beforeRouteEnter (to, from, next) {
    next((that) => {
      // 需要刷新页面的路由name列表
      const refreshNameList = ['main','circleMain']
      if (refreshNameList.indexOf(from.name) !== -1) {
        that.initSearchData()
      }
    })
  },
  // beforeRouteEnter(to, from) {
  //   next((vm) => {
  //     console.log(from.name);
  //     if (from.name === "organization") {
  //       // 从@页面回来重新渲染@列表
  //       if (vm.$route.query.selectMembers) {
  //         vm.attList = vm.$route.query.selectMembers
  //           .split(",")
  //           .filter((item) => item)
  //           .map((item) => {
  //             const [attId, attName] = item.split(":");
  //             return { attId, attName };
  //           });
  //       }
  //       vm.commentParams =
  //         localStorage.getItem("commentParams") &&
  //         JSON.parse(localStorage.getItem("commentParams"));
  //     } else if (
  //       (from.name === "createTopic" || from.name === "topicCategory") &&
  //       localStorage.getItem("refreshFlag")
  //     ) {
  //       // 发表完帖子刷新首页
  //       vm.records = [];
  //       vm.listQueryParams = {
  //         pageNum: 1,
  //         pageSize: 10,
  //         hasNextPage: true,
  //         categoryId: "1",
  //         sortType: "1",
  //       };
  //       vm.loadList();
  //       vm.horseLampList();
  //       localStorage.removeItem("refreshFlag");
  //     } else if (from.name === "topicDetail") {
  //       // 帖子详情页面返回回显帖子数据
  //       const { id, thumbsUpFlag, relationVO } = JSON.parse(
  //         localStorage.getItem("refreshData")
  //       );
  //       vm.records.forEach((item) => {
  //         if (item.id === id) {
  //           item.thumbsUpFlag = thumbsUpFlag;
  //           item.relationVO = relationVO;
  //         }
  //       });
  //     }
  //   });
  // },
  beforeRouteLeave (to, from, next) {
    // if (to.name === "organization") {
    //   localStorage.setItem("commentParams", JSON.stringify(this.commentParams));
    // }
    setItem("searchScrollTop", this.$refs.listWrap.scrollTop);
    document.getElementById("pickOverSearch").focus();
    next();
  },
  activated () {
    // 帖子详情页删出帖子后需要刷新列表
    const refreshFlag = getItem('refreshFlag')
    if (refreshFlag || refreshFlag === 'true') {
      this.tabAccording = true;
      this.listQueryParams.pageNum = 1;
      this.listQueryParams.hasNextPage = true;
      this.isSearched = false;
      this.loadList(true);
      removeItem('refreshFlag')
    } else {
      // 唤醒企业微信title不更新问题
      document.title = '搜索'
      this.$refs.listWrap.scrollTop = getItem("searchScrollTop")
    }
  },
  deactivated () {
    clearInterval(timer);
  },
  methods: {
    // 页面初始化
    initSearchData(){
      this.placeholder = this.$route.query.placeholder;
      const activeIndex = this.$route.query.activeIndex
      this.tabsActiveIndex = activeIndex === undefined ? 0 : parseInt(activeIndex + '');
      // this.tabsActiveIndex = 0
      this.whetherSearchPost = true
      this.whetherSearchCircle = true
      this.whetherSearchPersonnel = true
      this.listQueryParams.keyword = ''
      this.value = ''
      this.showTabs = this.$route.query.activeIndex === undefined
      this.tabAccording = false
      this.records = []
      this.$nextTick(()=>{
        this.$refs.searchBox.clearValue()
        this.$refs.searchBox.setAutofocus()
      })
    },
    horseLampList () {
      // newArticle().then((res) => {
      //   this.newArticleList = res
      // })
    },
    /**
     * todo 切换tabs
     */
    changeTabs (item, index) {
      this.tabsActiveIndex = index;
      this.pageTitle = this.tabsList[index].title;
      this.listQueryParams.hasNextPage = true;
      this.listQueryParams.pageNum = 1;
      this.loadList(true);
      this.whetherSearchPost = true;
      this.whetherSearchCircle = true;
      this.whetherSearchPersonnel = true;
    },
    // 点击清除
    handleClear () {
      this.tabAccording = false;
      this.whetherSearchPost = true;
      this.whetherSearchCircle = true;
      this.whetherSearchPersonnel = true;
    },
    loadMore () {
      this.loadList();
    },
    loadList (reset = false) {
      // 重置，清除脏数据
      if (reset) {
        this.records = [];
      }
      if (this.listQueryParams.keyword.trim()) {
        let loadListArray = ["loadArticle", "loadCircle", "loadPerson"];
        // 根据索引，加载对应的方法
        if (this[loadListArray[this.tabsActiveIndex]]) {
          this[loadListArray[this.tabsActiveIndex]](reset);
        } else {
          this.records = [];
        }
      } else {
        this.records = [];
      }
    },

    // 搜搜帖子
    loadArticle (reset) {
      let listQueryParams = this.listQueryParams;
      if (listQueryParams.hasNextPage) {
        if (this.$route.query.type === "person") {
          this.listQueryParams.type = this.$route.query.type;
        }
        this.isLoading = true;
        getSearch(this.listQueryParams)
          .then((res) => {
            listQueryParams.hasNextPage = res.current < res.pages;
            listQueryParams.pageNum = listQueryParams.pageNum + 1;
            let records = this.records;
            if (reset) {
              records = [];
            }
            this.total = res.totalCount;
            // this.times = res.times / 1000;
            res.records.forEach((item) => {
              let data = item.articleStatisticVO;
              if (data) {
                let count = records.filter(
                  (innerItem) => innerItem.articleId === data.articleId
                );
                // 先不处理
                if (count.length === 0) {
                  item.title = handleHeightLight(
                    item.title,
                    this.listQueryParams.keyword
                  )
                  item.content = handleContentHeightLight(
                    item.content,
                    this.listQueryParams.keyword
                  )
                  records.push(item)
                  this.records = records
                }
              }
            });
            this.whetherSearchPost = false;
            this.isSearched = true;
            this.isLoading = false;
            if (this.listQueryParams.keyword) {
              this.records = records;
            } else {
              this.records = []
            }
          })
          .catch(() => {
            this.isLoading = false;
          });
      }
    },

    // 搜搜圈子
    loadCircle (reset) {
      let listQueryParams = this.listQueryParams;
      if (listQueryParams.hasNextPage) {
        this.isLoading = true;
        searchCircleByTitle(this.listQueryParams)
          .then((res) => {
            listQueryParams.hasNextPage = res.current < res.pages;
            listQueryParams.pageNum = listQueryParams.pageNum + 1;
            let records = this.records;
            if (reset) {
              records = [];
            }
            this.total = res.totalCount;
            res.records.forEach((item) => {
              let count = records.filter(
                (innerItem) => innerItem.id === item.id
              );
              // 先不处理
              if (count.length === 0) {
                item.title = handleHeightLight(
                  item.title,
                  this.listQueryParams.keyword
                );
                item.introduction = handleHeightLight(
                  item.introduction,
                  this.listQueryParams.keyword
                );
                records.push(item);
              }
            });
            this.whetherSearchCircle = false;
            this.isSearched = true;
            this.isLoading = false;
            if (this.listQueryParams.keyword) {
              this.records = records;
            } else {
              this.records = []
            }
          })
          .catch(() => {
            this.isLoading = false;
          });
      }
    },

    // 搜索人员
    loadPerson (reset) {
      let listQueryParams = this.listQueryParams;
      if (listQueryParams.hasNextPage) {
        let postData = JSON.parse(JSON.stringify(this.listQueryParams));
        postData.username = postData.keyword;
        this.isLoading = true;
        getZeekrUser(postData)
          .then((res) => {
            listQueryParams.hasNextPage = res.current < res.pages;
            listQueryParams.pageNum = listQueryParams.pageNum + 1;
            let records = this.records;
            if (reset) {
              records = [];
            }
            this.total = res.totalCount;
            res.records.forEach((item) => {
              let count = records.filter(
                (innerItem) => innerItem.userId === item.userId
              );
              // 先不处理
              if (count.length === 0) {
                item.username = handleHeightLight(
                  item.username,
                  this.listQueryParams.keyword
                );
                records.push(item);
              }
            });
            this.whetherSearchPersonnel = false;
            this.isSearched = true;
            this.isLoading = false;
            if (this.listQueryParams.keyword) {
              this.records = records;
            } else {
              this.records = []
            }
          })
          .catch(() => {
            this.isLoading = false;
          });
      }
    },

    handleBack () {
      this.$router.back();
    },
    handleCancel () {
      this.listQueryParams.keyword = "";
      this.isSearched = false;
      this.records = [];
      this.$router.back();
    },
    handleSearch () {
      this.tabAccording = true;
      this.listQueryParams.pageNum = 1;
      this.listQueryParams.hasNextPage = true;
      this.isSearched = false;
      this.listQueryParams.keyword = this.listQueryParams.keyword.trim()
      if (this.listQueryParams.keyword) {
        this.loadList(true);
      } else {
        this.records = [];
      }
    },
    async follow (data, index) {
      const {id, myFollow} = data;
      if (myFollow) {
        await followRemove(id);
        this.records[index].commonFollowCount--;
      } else {
        await attAdd(id);
        this.records[index].commonFollowCount++;
      }
      this.records[index].myFollow = !myFollow;
    },
  },
};
</script>

<style lang="scss" scoped>
.search {
  background-color: #ffffff;
  height: 100%;
  display: flex;
  flex-direction: column;

  page_title {
    font-size: 18px;
    color: #000000;
    line-height: 18px;
  }

  .search_input {
    display: flex;
    flex-direction: row;
    margin-top: 12px;

    .searchBox {
      flex: 1;
      margin: 0;
    }

    .head-right {
      display: flex;
      align-items: center;
      padding: 0 15px 0 0;

      .text {
        color: #000000;
        line-height: 14px;
        font-size: 14px;
        width: 40px;
        text-align: center;
      }
    }
  }

  .timeWarp {
    color: #999999;
    font-size: 13px;
    margin-left: 20px;
    height: 30px;
    line-height: 30px;
  }

  .listWrap {
    flex: 1;
    overflow: auto;

    .tabs {
      position: relative;
      display: flex;
      flex-direction: row;
      align-items: center;
      margin-top: 15px;
      padding: 0 15px;
      height: 32px;

      .tabs_item {
        display: flex;
        flex-direction: row;
        align-items: center;
        //height: 32px;
        line-height: 30px;
        padding: 0 17px 0 14px;

        .tabs_text {
          display: inline-block;
          font-size: 14px;
          color: #6d7278;
          letter-spacing: 0.01px;
          border-bottom: 2px solid transparent;
        }

        .tabsActive {
          color: #000000;
          border-bottom: 2px solid $mainColor;
        }
      }
    }
  }
}
</style>
