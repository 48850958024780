<template>
  <div>
    <div class="search-content" v-if="content.length > 0">
      <circleItem
        :isShowIntro="true"
        v-for="(item, index) in content"
        :key="index"
        :item="item"
        class="topicItem"
      />
    </div>
    <div v-else class="nullText">
      <img src="/images/appMainView/searchNull.png" alt="" />
      <p v-if="whetherSearch">请进行搜索</p>
      <div v-else>
        <p>暂无圈子结果</p>
        <div v-if="userDetail.isCanCreateCircle" @click="CreateCircle" class="go-create">去创建 ></div>
      </div>
    </div>
  </div>
</template>

<script>
import circleItem from "@/components/circle/circleItem";
import {mapGetters} from "vuex";

export default {
  components: {
    circleItem,
  },
  computed:{
    ...mapGetters(['userDetail']),
  },
  data() {
    return {};
  },

  props: {
    content: {
      type: Array,
      default: () => [],
    },
    whetherSearch: {
      type: Boolean,
      default: () => true,
    },
  },
  methods: {
    CreateCircle() {
      this.$router.push({ name: "myCreateCircle" });
    },
    handleTopicClick({ id } = {}) {
      this.$router.push({ name: "topicDetail", query: { id } });
    },
    loadMore() {
      this.$emit("loadMore");
    },
    follow(data, index) {
      this.$emit("follow", data, index);
    },
  },
};
</script>

<style lang="scss" scoped>
.topicItem {
  margin-top: 10px;
}
.go-create {
  color: #fe9056;
  margin-top: 10px;
}
.nullText {
  font-size: 14px;
  color: $mainTextColor;
  text-align: center;
  margin-top: 44px;

  > img {
    width: 212px;
  }
}
</style>
