<template>
  <div>
    <div class="search-content" v-if="content.length > 0">
      <div
        class="user-wrap"
        v-for="item in content"
        :key="item.userId"
        @click="goUserDetail(item)"
      >
        <AvatarFrame class="wrap-left" :user-info="item"></AvatarFrame>
        <div class="wrap-right">
          <div class="user-name" v-html="item.username"></div>
          <div class="user-dept">{{ item.deptStr }}</div>
        </div>
      </div>
    </div>
    <!-- 加载中动画 -->
    <!--    <van-skeleton v-if="isLoading" :row="20" />-->
    <!-- 查询为空 -->
    <div v-else class="nullText">
      <img src="/images/appMainView/searchNull.png" alt="" />
      <p v-if="whetherSearch">请进行搜索</p>
      <p v-else>暂无人员结果</p>
    </div>
  </div>
</template>

<script>
import AvatarFrame from '@/components/avatarFrame'

export default {
  components: {
    AvatarFrame
  },
  data () {
    return {};
  },
  props: {
    isLoading: {
      type: Boolean,
      default: false,
    },
    content: {
      type: Array,
      default: () => [],
    },
    whetherSearch: {
      type: Boolean,
      default: () => true,
    },
  },
  create () {
  },
  methods: {
    handleTopicClick ({id} = {}) {
      this.$router.push({name: "topicDetail", query: {id}});
    },
    loadMore () {
      this.$emit("loadMore");
    },
    goUserDetail ({userId}) {
      this.$router.push({name: "userInfoDetail", params: {userId}});
    },
  },
};
</script>

<style lang="scss" scoped>
.search-content {
  padding: 10px 20px;

  .user-wrap {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 12px 0;

    .wrap-left {
      width: 44px;
      height: 44px;
    }

    .wrap-right {
      line-height: 22px;
      margin-left: 10px;

      .user-name {
        font-size: 14px;
        font-weight: 500;
        text-align: LEFT;
      }

      .user-dept {
        font-size: 12px;
        color: #6d7278;
      }
    }
  }
}

.topicItem {
  margin-top: 10px;
}

.nullText {
  font-size: 14px;
  color: $mainTextColor;
  text-align: center;
  margin-top: 44px;

  > img {
    width: 212px;
  }
}
</style>
