<template>
  <div>
    <div class="search-content" v-if="content.length > 0">
      <topic
        v-for="(item, index) in content"
        :key="index"
        :data="item"
        :isDetail="true"
        @click="handleTopicClick"
        @follow="follow(item, index)"
        class="topicItem"
        @userInfoClick="handleToUserDetail(item)"
        @share="handleShare"
        @originalClick="handleTopicClick"
        @comment="handleComment"
        @handleNewActive="handleNewActive"
        @like="like($event, index)"
        @favorite="favorite($event, index)"
      />
    </div>
    <div v-else class="nullText">
      <img src="/images/appMainView/searchNull.png" alt="" />
      <p v-if="whetherSearch">请进行搜索</p>
      <p v-else>暂无帖子结果</p>
    </div>
    <commentToolDialog
      :show-dialog.sync="showDialog"
      :isAnonymousComment.sync="isAnonymousComment"
      v-model="commentContent"
      hide-relation
      hide-img
      @publish="handlePublishComment"
    />
  </div>
</template>

<script>
import topic from "@/components/topicItem";
import commentToolDialog from "@/components/commentToolDialog";
import { addThumbsup, removeThumbsup } from "@/api/thumbsup";
import { addFavorites, removeFavorites } from "@/api/favorites";
import { addComment } from "@/api/comment";
const ENTITY_TYPE_ARTICLE = 2;

export default {
  components: {
    topic,
    commentToolDialog,
  },
  data() {
    return {
      noDataInfo: {
        title1: "暂未相关帖子",
      },
      pageNum: 1,
      pageSize: 20,
      listQueryParams: {
        pageNum: 1,
        pageSize: 10,
        hasNextPage: true,
      },
      showDialog: false,
      isAnonymousComment: false,
      commentContent: "",
      isLoad: false,
    };
  },

  props: {
    content: {
      type: Array,
      default: () => [],
    },
    whetherSearch: {
      type: Boolean,
      default: () => true,
    },
  },
  methods: {
    handleTopicClick({ articleId } = {}) {
      this.$router.push({ name: "topicDetail", query: { id: articleId } });
    },
    loadMore() {
      this.$emit("loadMore");
    },
    follow(data, index) {
      this.$emit("follow", data, index);
    },
    like(res, index) {
      // console.log(res);
      // 取消点赞
      if (res.isThumbsUp) {
        removeThumbsup({
          entityId: res.articleId,
          entityType: 1,
          // eslint-disable-next-line no-unused-vars
        }).then(() => {
          let data = this.content[index];
          this.content[index].isThumbsUp = !res.isThumbsUp;
          let count = data.articleStatisticVO.thumbsUpCount - 1;
          if (count < 0) count = 0;
          data.articleStatisticVO.thumbsUpCount = count;
        });
      } else {
        // 添加点赞
        addThumbsup({
          entityId: res.articleId,
          entityType: 1,
        }).then(() => {
          let data = this.content[index];
          this.content[index].isThumbsUp = !res.isThumbsUp;
          // if (!data.articleStatisticVO) {
          //   data.articleStatisticVO = {};
          // }
          data.articleStatisticVO.thumbsUpCount =
            data.articleStatisticVO.thumbsUpCount + 1;
        });
      }
    },

    favorite(res, index) {
      // console.log(res);
      // debugger;
      // 取消收藏
      if (res.isFavorites) {
        removeFavorites({
          entityId: res.articleId,
          entityType: "1",
        }).then(() => {
          let data = this.content[index];
          this.content[index].isFavorites = !res.isFavorites;
          let count = data.articleStatisticVO.favoritesCount - 1;
          if (count < 0) count = 0;
          data.articleStatisticVO.favoritesCount = count;
        });
      } else {
        // 添加收藏
        addFavorites({
          entityId: res.articleId,
          entityType: "1",
        }).then(() => {
          let data = this.content[index];
          this.content[index].isFavorites = !res.isFavorites;
          if (!data.articleStatisticVO) {
            data.articleStatisticVO = {};
          }
          data.articleStatisticVO.favoritesCount =
            data.articleStatisticVO.favoritesCount + 1;
        });
      }
    },
    handleNewActive(item) {
      const index = this.content.findIndex((i) => {
        return i.articleId === item.articleId;
      });
      // console.log(this.content[index]);
      this.content[index].articleVoteVO = item;
    },
    handleSwiperClick(data) {
      const type = data.type;
      if (type === 0 || type === 1) {
        this.$router.push({
          name: "topicDetail",
          query: { id: data.articleId },
        });
      } else if (type === 2) {
        this.$router.push({ name: "forwardToWechat", query: { id: data.id } });
      } else if (type === 3) {
        window.location.href = data.content;
      }
    },
    /**
     * 分享事件
     */
    handleShare({ id: quoteId } = {}) {
      this.$router.push({ name: "createTopic", query: { quoteId } });
    },
    handleToUserDetail(item) {
      this.$router.push({ name: "userInfoDetail", params: { userId: item.userBasicVO.userId } });
    },
    handleComment(item) {
      this.showDialog = true;
      this.commentParams = item;
      // console.log(this.commentParams);
    },
    async handlePublishComment() {
      const {
        commentContent: content,
        commentParams: { articleId: entityId },
        isAnonymousComment: isAnonymous,
      } = this;
      const entityType = ENTITY_TYPE_ARTICLE;
      if (!content.trim()) {
        this.$notify({ message: "评论内容不能为空", type: "danger" });
        return;
      }
      await addComment({ content, entityId, entityType, isAnonymous });
      this.showDialog = false;
      this.commentParams = {};
      this.commentContent = "";
      this.content = this.content.map((item) => {
        if (item.articleId !== entityId) {
          return item;
        }
        const { articleStatisticVO } = item;
        return {
          ...item,
          articleStatisticVO: {
            ...articleStatisticVO,
            commentCount: articleStatisticVO.commentCount + 1,
          },
        };
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.topicItem {
  margin-top: 10px;
}

.nullText {
  font-size: 14px;
  color: $mainTextColor;
  text-align: center;
  margin-top: 44px;
  > img {
    width: 212px;
  }
}
</style>
